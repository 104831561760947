import React, { useState } from "react";
import defaultProfileImage from "../../../images/ProfilePicture/HH_ProfileDefaultImage.png";
import LoadingSpinner from "../../../common/components/LoadingSpinner";

const WorkerCard = ({ worker }) => {
  const [loading, setLoading] = useState(true);
  const workerAge = worker.date_of_birth
    ? `${
        new Date().getFullYear() - new Date(worker.date_of_birth).getFullYear()
      } años`
    : "";

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div className="worker-card-nw">
      {/* Left: Image */}
      <div className="worker-image-container-nw">
        {loading && <LoadingSpinner />}
        <img
          src={worker.user.profile_image || defaultProfileImage}
          alt="Worker Card Profile"
          className={`worker-profile-image-nw ${loading ? "hidden" : ""}`}
          onLoad={handleImageLoad}
          width={200}
          height={200}
        />
      </div>

      {/* Right: Content */}
      <div className="worker-content-nw">
        <p className="worker-name-nw">
          {worker.user.first_name}, {workerAge}
        </p>
        <p className="worker-location-nw">
          {worker.location?.municipality?.name || "Localidad no asignada"},{" "}
          {worker.location?.province?.name || "Provincia no asignada"}
        </p>
        <div className="worker-job-type-nw">
          {worker.work_arrangements?.length > 0 ? (
            worker.work_arrangements.map((work_arrangement, index) => (
              <span
                className="worker-job-arrangement-nw"
                key={work_arrangement.id}
              >
                {work_arrangement.value_display}
                {index < worker.work_arrangements.length - 1 && ", "}
              </span>
            ))
          ) : (
            <p className="worker-job-arrangement-nw">
              Modalidad de trabajo sin asignar
            </p>
          )}
        </div>
        <p className="worker-introduction-nw">
          {worker.introduction
            ? worker.introduction.substring(0, 100)
            : "Sin descripción disponible"}
          {worker.introduction && worker.introduction.length > 100 && "..."}
        </p>
        <hr className="worker-divider-nw" />
        <div className="worker-services-nw">
          {worker.services.map((service) => (
            <span className="worker-service-nw" key={service.id}>
              {service.title_display}
            </span>
          ))}
        </div>
        {/* <p className="worker-availability-nw">
          Días disponibles: {worker.availability || "No especificados"}
        </p> */}
      </div>
    </div>
  );
};

export default WorkerCard;
