import React, { useState, useEffect } from 'react';
import WorkerCard from './WorkerCard';
import { Link } from 'react-router-dom';
import WorkersPagination from '../WorkersPage/WorkersPagination';
import LoadingSpinner from '../../../common/components/LoadingSpinner';

const WorkersList = ({ filter }) => {
  const [workers, setWorkers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [workersPerPage] = useState(50);
  const [showAllWorkers, setShowAllWorkers] = useState(false);

  useEffect(() => {
    const fetchWorkers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/workers/`, {
          method: 'GET'
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setWorkers(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchWorkers();
  }, [filter]);

  const applyFilters = (worker) => {
    if (!filter) {
      return true;
    }

    const filterParams = filter.split('&');

    return filterParams.every(param => {
      const [key, value] = param.split('=');

      if (key === 'services') {
        const filterValues = value.split(',');
        return filterValues.some(filterService => {
          return worker.services.some(workerService => workerService.title_display === filterService);
        });
      } else if (key === 'work_arrangements') {
        const filterValues = value.split(',');
        return filterValues.some(filterWorkArrangement => {
          return worker.work_arrangements.some(workerWorkArrangement => workerWorkArrangement.value_display === filterWorkArrangement);
        });
      } else if (key === 'tasks') {
        const filterValues = value.split(',');
        return filterValues.some(filterTask => {
          return worker.tasks.some(workerTask => workerTask.title_display === filterTask);
        });
      } else if (key === 'languages') {
        const filterValues = value.split(',');
        return filterValues.some(filterLanguage => {
          return worker.languages.some(workerLanguage => workerLanguage.name_display === filterLanguage);
        });
      } else if (key === 'work_shifts') {
        const filterValues = value.split(',');
        return filterValues.some(filterShift => {
          const [day, shift] = filterShift.split(':');
          return worker.work_shifts.some(workerShift => workerShift.day_display === day && workerShift.shift_display === shift);
        });
      } else if (key === 'province') {
        return worker.location?.province?.id && worker.location.province.id === parseInt(value);
      } else if (key === 'municipality') {
        return worker.location?.municipality?.id && worker.location.municipality.id === parseInt(value);
      }
      return false;
    });
  };

  const filteredWorkers = workers.filter(applyFilters);
  const showPagination = filteredWorkers.length > workersPerPage;
  const currentWorkers = showAllWorkers ? filteredWorkers : filteredWorkers.slice((currentPage - 1) * workersPerPage, currentPage * workersPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handleShowAll = () => {
    setShowAllWorkers(true);
    setCurrentPage(1);
  };

  if (loading) {
    return <div className="workers-list"><LoadingSpinner /></div>; 
  }

  if (error) {
    return <div className="workers-list"><div><p className="error-message">Error: {error}</p></div></div>;
  }

  return (
    <div className="workers-list">
      {filteredWorkers.length === 0 ? (
        <div className="no-results-container">
          <p>No hay trabajadores/as disponibles en este momento.</p>
        </div>
      ) : (
        <>
          <div className="section-title">
            <h2>{filteredWorkers.length} trabajadores/as disponibles</h2>
          </div>
          <div className="grid-container-workers">
            {currentWorkers.map(worker => (
              <Link key={worker.id} to={`/worker-profile/${worker.id}`} className="worker-link">
                <WorkerCard worker={worker} />
              </Link>
            ))}
            {showPagination && !showAllWorkers && <WorkersPagination workersPerPage={workersPerPage} totalWorkers={filteredWorkers.length} paginate={paginate} showAll={handleShowAll} />}
          </div>
        </>
      )}
    </div>
  );
};

export default WorkersList;
