import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../AuthProvider';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';

const ParentProfileMembership = () => {
  const { authTokens, userData } = useContext(AuthContext);
  const [membership, setMembership] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMembership = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/parent-profile/${userData.profile_id}/membership/get/`, {
          headers: {
            'Authorization': `Bearer ${authTokens.access}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setMembership(data || { membership_type: null, start_date: null, end_date: null, payment: null, status: null });
        } else {
          setError('Error al obtener la membresía.');
        }
      } catch (error) {
        setError('Error al obtener la membresía.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchMembership();
  }, [userData, authTokens]);

  return (
    <div className="">
      {/* <div className="user-account-actions"> profile-edit-section membership
        <div className="user-account-actions-left">
          <h2>Membresía</h2>
        </div>
      </div>
      <div className="profile-edit-section-content">
        {isLoading ? (
          <LoadingSpinner />
        ) : error ? (
          <p className="error-message">{error}</p>
        ) : membership && membership.start_date ? (
          <div className="table-container">
            <table className="membership-table">
              <thead>
                <tr>
                  <th>Tipo</th>
                  <th>Fecha de inicio</th>
                  <th>Fecha expiración</th>
                  <th>Método de Pago</th>
                  <th>Importe</th>
                  <th>Estado</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="Tipo">{membership.membership_type?.name || 'No disponible'}</td>
                  <td data-label="Fecha de inicio">{membership.start_date || 'No disponible'}</td>
                  <td data-label="Fecha expiración">{membership.end_date || 'No disponible'}</td>
                  <td data-label="Método de Pago">{membership.payment ? membership.payment.payment_method_display : 'No disponible'}</td>
                  <td data-label="Importe">{membership.payment ? `$${membership.payment.amount}` : 'No disponible'}</td>
                  <td data-label="Estado">{membership.status ? 'Activa' : 'Inactiva'}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <div className="job-offer-list-empty">
            <h2 className="profile-edit-section-title">Sin membresía</h2>
            <div className="popup-intro-description">
              <p>Con una membresía serás capaz de publicar ofertas laborales, contactar de forma directa a los trabajadores/as y muchos más beneficios.</p>
            </div>
            <div className="buttons-container">
              <button className="secondary-btn" onClick={() => navigate('/memberships')}>Membresias</button>
            </div>
          </div>
        )}
      </div> */}
    </div>
  );
};

export default ParentProfileMembership;
