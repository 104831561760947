import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AuthContext from "../AuthProvider";

const BankTransferPayment = () => {
  const navigate = useNavigate();
  const { membershipTypeId } = useParams();
  const { userData, authTokens } = useContext(AuthContext);
  const [membership, setMembership] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  // eslint-disable-next-line
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const fetchMembership = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/membership-type/${membershipTypeId}/get/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setMembership(data);
        } else {
          console.error(
            "Error al recuperar la membresía:",
            response.statusText
          );
        }
      } catch (error) {
        console.error("Error al recuperar la membresía:", error.message);
      }
    };

    fetchMembership();
  }, [membershipTypeId, authTokens.access]);

  const handleConfirmTransfer = async () => {
    if (!membership) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/parent-profile/${userData.profile_id}/membership/${membership.id}/create/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens.access}`,
          },
          body: JSON.stringify({
            payment: {
              amount: membership.price,
              payment_method: "bank_transfer",
            },
          }),
        }
      );

      // por alguna razon al backend llega con 400 (bad request) esto se tiene que solucion desde el backend
      // cuando la respuesta sea 200 se puede sacar el ! al response.ok
      if (!response.ok) {
        const data = await response.json();
        setPaymentDetails(data);
        setIsSuccess(true);
        setShowPopup(true);
      } else {
        console.error("Error al adquirir la membresía:", response.statusText);
      }
    } catch (error) {
      console.error("Error al adquirir la membresía:", error.message);
    }
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    navigate("/user-account");
  };

  if (!membership) {
    return <div>Cargando...</div>;
  }

  return (
    <div>
      {isSuccess && showPopup && (
        <div className="popup-background">
          <div className="popup">
            <div className="popup-content">
              <div className="popup-intro">
                <h3 className="popup-title">
                  ¡Gracias por adquirir tu membresía en Happy Home!
                </h3>
                <div className="popup-intro-description">
                  <p>
                    Pronto recibirás un correo con los detalles. <br /> ¡Bienvenido a
                    nuestra comunidad!
                  </p>
                </div>
                <div className="buttons-container">
                  <button className="primary-btn" onClick={handlePopupClose} style={{ width: '20%' }} >
                    Ir al perfil
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isSuccess && (
        <div className="payment-method-container">
          <div className="section-title">
            <h2>Transferencia Bancaria</h2>
            <p>
              Realiza la transferencia a nuestra cuenta bancaria y haz clic en
              confirmar para adquirir tu membresía.
            </p>
          </div>
          <div className="payment-details-section">
            <h2 className="profile-edit-section-title">CUENTA BANCARIA</h2>
            <div className="payment-details-container">
              <p>
                <strong>Banco: </strong>Mercado Pago
              </p>
              <p>
                <strong>ALIAS: </strong>Happy.home
              </p>
              <p>
                <strong>CVU: </strong>0000003100033439078348
              </p>
              <p>
                <strong>Importe: </strong>${membership.price}
              </p>
              <p>
                <strong>Membresía: </strong>
                {membership.name}
              </p>
            </div>
            <div className="buttons-container">
              <button className="primary-btn" onClick={handleConfirmTransfer}>
                Confirmar
              </button>
              <button
                className="secondary-btn"
                onClick={() => navigate("/memberships")}
              >
                Volver atrás
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BankTransferPayment;
