import React from 'react';
import defaultProfileImage from '../../../images/ProfilePicture/HH_ProfileDefaultImage.png';

const JobOfferCard = ({ jobOffer }) => {
  return (
    <div className="worker-card">
      <div className="worker-container">
        <div className="left-container">
          <img src={jobOffer.parent_profile.user.profile_image || defaultProfileImage} alt="Profile" className="worker-profile-image" />
        </div>
        <div className="right-container">
          <div className="worker-top-container">
            <div className="worker-name"><p><strong>{jobOffer.title}</strong></p></div>
          </div>

          <p className="worker-detail-line">
            {jobOffer.location?.municipality?.name && jobOffer.location?.province?.name
              ? `${jobOffer.location.municipality.name}, ${jobOffer.location.province.name}`
              : "Ubicación sin asignar"}
          </p>

          <div className="worker-about">
    
            <div className="worker-introduction">
              <p>{jobOffer.description ? jobOffer.description.substring(0, 90) : ''}{jobOffer.description && jobOffer.description.length > 90 && '...'}</p>
            </div>

            <div className="worker-services">
              {jobOffer.services.map(service => (
                <p className="worker-service" key={service.id}>{service.title_display}</p>
              ))}
            </div>

            <div className="job-offer-footer">
              <div className="job-offer-work-arrangements">
                {jobOffer.work_arrangements.map((work_arrangement, index) => (
                  <p className="worker-work-arrangement" key={work_arrangement.id}>
                    {work_arrangement.value_display}
                    {index < jobOffer.work_arrangements.length - 1 && ', '}
                  </p>
                ))}
              </div>
              <div className="job-offer-author">
              <p>por {jobOffer.parent_profile.user.first_name}</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default JobOfferCard;