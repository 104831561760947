import React, { useState, useEffect } from 'react';
import JobOfferCard from './JobOfferCard';
import { Link } from 'react-router-dom';
import JobOffersPagination from './JobOffersPagination';
import LoadingSpinner from '../../../common/components/LoadingSpinner';

const JobOffersList = ({ filter }) => {
  const [jobOffers, setJobOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [jobOffersPerPage] = useState(50);
  const [showAllJobOffers, setShowAllJobOffers] = useState(false);

  useEffect(() => {
    const fetchJobOffers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/job-offers/`, {
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setJobOffers(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
    fetchJobOffers();
  }, [filter]);

  const applyFilters = (jobOffer) => {
    if (!filter) {
      return true;
    }
    const filterParams = filter.split('&');
    return filterParams.every(param => {
      const [key, value] = param.split('=');
      if (key === 'services') {
        const filterValues = value.split(',');
        return filterValues.some(filterService => {
          return jobOffer.services.some(jobOfferService => jobOfferService.title_display === filterService);
        });
      } else if (key === 'work_arrangements') {
        const filterValues = value.split(',');
        return filterValues.some(filterWorkArrangement => {
          return jobOffer.work_arrangements.some(jobOfferWorkArrangement => jobOfferWorkArrangement.value_display === filterWorkArrangement);
        });
      } else if (key === 'tasks') {
        const filterValues = value.split(',');
        return filterValues.some(filterTask => {
          return jobOffer.tasks.some(jobOfferTask => jobOfferTask.title_display === filterTask);
        });
      } else if (key === 'languages') {
        const filterValues = value.split(',');
        return filterValues.some(filterLanguage => {
          return jobOffer.languages.some(jobOfferLanguage => jobOfferLanguage.name_display === filterLanguage);
        });
      } else if (key === 'work_shifts') {
        const filterValues = value.split(',');
        return filterValues.some(filterShift => {
          const [day, shift] = filterShift.split(':');
          return jobOffer.work_shifts.some(jobOfferShift => jobOfferShift.day_display === day && jobOfferShift.shift_display === shift);
        });
      } else if (key === 'province') {
        return jobOffer.location?.province?.id && jobOffer.location.province.id === parseInt(value);
      } else if (key === 'municipality') {
        return jobOffer.location?.municipality?.id && jobOffer.location.municipality.id === parseInt(value);
      }
      return false;
    });
  };

  const filteredJobOffers = jobOffers.filter(applyFilters);
  const showPagination = filteredJobOffers.length > jobOffersPerPage;
  const currentJobOffers = showAllJobOffers ? filteredJobOffers : filteredJobOffers.slice((currentPage - 1) * jobOffersPerPage, currentPage * jobOffersPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleShowAll = () => {
    setShowAllJobOffers(true);
    setCurrentPage(1);
  };

  if (loading) {
    return <div className="job-offers-list"><LoadingSpinner /></div>; 
  }

  if (error) {
    return <div className="job-offers-list"><div><p className="error-message">Error: {error}</p></div></div>;
  }

  return (
    <div className="job-offers-list">
      {filteredJobOffers.length === 0 ? (
        <div className="no-results-container">
          <p>No hay ofertas laborales disponibles en este momento.</p>
        </div>
      ) : (
        <>
          <div className="section-title">
            <h2>{filteredJobOffers.length} ofertas laborales disponibles</h2>
          </div>
          <div className="grid-container-workers">
            {currentJobOffers.map(jobOffer => (
              <Link key={jobOffer.id} to={`/job-offer/${jobOffer.id}`} className="job-link">
                <JobOfferCard jobOffer={jobOffer} />
              </Link>
            ))}
          </div>
          {showPagination && !showAllJobOffers && <JobOffersPagination jobOffersPerPage={jobOffersPerPage} totalJobOffers={filteredJobOffers.length} paginate={paginate} showAll={handleShowAll} />}
        </>
      )}
    </div>
  );
};

export default JobOffersList;
